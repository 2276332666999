import { height } from "@mui/system";
import React from "react";
import styled from "styled-components";
import { Button } from "../../components/button";
import { Marginer } from "../../components/marginer";
import { PageContainer } from "../../components/PageContainer";

import TopSectionBackgroundImg from "../../images/FactImg.jpg"
import TheLogo from "../../images/logo_transparent.png"
import CalenderAlignment from "../timer/CalenderAlignment";
import TimeRecord from "../timer/TimeRecord";
import TrafficLight from "../trafficLight/TrafficLight";

const TopSectionContainer = styled.div`
    width: 100%;
    height: 1280px;
    background: url(${TopSectionBackgroundImg});
    background-position: 0px -850px;
    background-size: cover;
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(38, 70, 83, 0.9);
  display: flex;
  flex-direction: column;
`

const TopSectionInnerContainer = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: space-evenly;
`;

const LogoImage = styled.div`
 width: 37em;
 height: 32em;

 img {
    width: 100%;
    height: 100%;
 }
`;

const Title = styled.h2`
 margin: 0;
 font-size: 24em;
 color: #fff;
 line-height: 1.7;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const LogoTitle = styled.h2`

    margin: 0%;
    font-size: 35px;
    color: #000000;
    font-weight: 900;

`;

const LogoSubTitle = styled.h3`

    margin: 0%;
    line-height: 1.4;
    font-size: 20px;
    color: #b3afaf;
    font-weight: 700;

`;

export function Sections(props){

    const { children } = props;

    return (
    <TopSectionContainer>
        <BackgroundFilter>
            <TopSectionInnerContainer>
                <LogoContainer>
                    <LogoTitle>Line Status</LogoTitle>
                    <Marginer direction = "vertical" margin={80}/>
                    <TrafficLight/>
                </LogoContainer>

                <TimeRecord/>
                <CalenderAlignment/>
            </TopSectionInnerContainer>
        </BackgroundFilter>
    </TopSectionContainer>
    );
}