import React from "react";
import {useEffect} from "react";
import { InnerPageContainer, PageContainer } from "../../components/PageContainer";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import { TopSection } from "./Topsection";
import styled from "styled-components";

import TopSectionBackgroundImg from "../../images/FactImg.jpg"
import { Navbar } from "../../components/navbar";



//import "../../styles.css";
import { Marginer } from "../../components/marginer";


import TrafficLight from "../../components/trafficLight/TrafficLight";
import { StrictMode } from "react";

import TimeRecord from "../../components/timer/TimeRecord";
import CalenderAlignment from "../../components/timer/CalenderAlignment";



export function HomePage(props){
   //useEffect(() => document.title = props.title, []);
    return(
      <PageContainer>
        <TopSection>
          <Navbar />
        </TopSection>
      </PageContainer>
      
    );
} 