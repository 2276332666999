import React from "react";
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react-v1';

import styled from "styled-components";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faTwitter} from "@fortawesome/free-brands-svg-icons";

const FooterContainer = styled.div`
     width: 100%;
    height: 150 px;
    display: flex;
    flex-direction: column;
    padding: 2em 3em;
    justify-content: space-between;
    padding-bottom: 0 ;
    border-top: 0.8px solid #fbfbfb;
`
 
const TopContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1em;
    
`;


const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:not(:last-of-type){
        margin-right: 6%;

    }
`
const MiddleContainer = styled.div`
    display: flex;
    margin-left: 60%;
    margin-top: 25px;
    place-items: 'center';
    justify-items: flex-end;
    
`;

const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 35px;
    align-items: center;
    border-top: 0.8px solid #fbfbfb;
    padding: 0%;
`;

const RightBottomContainer = styled.div`
  display: flex  ;
`;

const LeftBottomContainer = styled.div`
    display: flex;
`;

const Title = styled.h2`
    margin: 0%;
    margin-bottom: 0px;
    color: #e5e2e2;
    font-weight: 550;
    font-size: 20;
`;

const TitleSub = styled.h6`
    margin: 0%;
    margin-left: 20px;
    color: #bcbcbc;
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-weight: 4000;
    font-size: 20;
`;

const FLink = styled.a`
    text-decoration: none;
    color: #b3b0b0;
    font-weight: 400;
    cursor: pointer;

    &:not(:last-of-type){
        margin-bottom: 6px;

    }
`;

const IconStyler = styled.div`

    font-size: 25px;
    color: white;
    margin-left: 20px;
    padding-top: 5px;
    cursor: pointer;
    align-items: center;
    transition: color, 200ms ease-in-out;

    &:hover{
        color: gray;
    }
`;

export function Footer(props){

    return (<FooterContainer>
        <TopContainer>
            
                <ContentContainer>
                    <Title> About</Title>
                    <FLink> Address </FLink>
                </ContentContainer>

                <ContentContainer>
                    <Title> Access </Title>
                    <FLink> Login </FLink>
                    <FLink> Logout </FLink>
                </ContentContainer>
            <MiddleContainer>
                <AmplifySignOut />
            </MiddleContainer>
        </TopContainer>

        <BottomContainer>
                <LeftBottomContainer>
                    <Title> Z-Prime Platform</Title>
                    <TitleSub> &#169; All Rights reserved. 2022</TitleSub>
                    
                </LeftBottomContainer>
                <RightBottomContainer>
                    <IconStyler>
                        <FontAwesomeIcon icon={faFacebook}/>
                    </IconStyler>
                    <IconStyler>
                        <FontAwesomeIcon icon={faTwitter}/>
                    </IconStyler> 
                </RightBottomContainer>
        </BottomContainer>
        

    </FooterContainer>
    );

}