import React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.button`
    border: none;
    outline: none;
    color: #fff;
    padding: 6px 2em;
    font-size: ${({size}) => size ? size + "px" : "18px"};
    font-weight: 600;
    border-radius: 3px;
    background-color: #bb8210;
    cursor: pointer;

    &:focus{
        outline: none;
    }
`;

export function Button(props){
    const { size } = props;

    return <ButtonWrapper size = {size}>{props.children}</ButtonWrapper>;

}