import React from "react";
import styled from "styled-components";
import { Button } from "../button";
import { Marginer } from "../marginer";

const NavbarContainer = styled.div`
    width: 100%;
    height: 65px;

    display: flex ;
    align-items: center;
    justify-content: left;
    padding: 0 1.5em ;
`;

const AccessibilityContainer = styled.div`
    display: flex;
    align-items: center;
`;

const AnchorLink = styled.a`
    font-size: 9 px;
    font-weight: 550;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    transition: all 200ms ease-in-out;

    &:hover{
        filter: contrast(0.6);
    }
`;

export function  Navbar(props){

    return (
        <NavbarContainer>
            <AccessibilityContainer>
                <AnchorLink> Specialist </AnchorLink>
                <Marginer direction="horizontal" margin={12}/>
                <Button size={11}>Register</Button>
                <Marginer direction="horizontal" margin={8}/>
                <AnchorLink> Login </AnchorLink>
            </AccessibilityContainer>
        </NavbarContainer>
    );
}