import { height } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/button";
import { Marginer } from "../../components/marginer";
import { PageContainer } from "../../components/PageContainer";

import TopSectionBackgroundImg from "../../images/iQoniqBG.jpg"
import TheLogo from "../../images/logo_transparent.png"

const TopSectionContainer = styled.div`
    width: 100%;
    height: 1080px;
    background: url(${TopSectionBackgroundImg});
    background-position: 0px -100px;
    background-size: cover;
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(24, 10, 46, 0.611);
  display: flex;
  flex-direction: column;
`

const TopSectionInnerContainer = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: space-evenly;
`;

const LogoImage = styled.div`
 width: 37em;
 height: 32em;

 img {
    width: 100%;
    height: 100%;
 }
`;

const Title = styled.h2`
 margin: 0;
 font-size: 24em;
 color: #fff;
 line-height: 1.7;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

const LogoTitle = styled.h2`

    margin: 0%;
    font-size: 35px;
    color: #fff;
    font-weight: 900;

`;

const LogoSubTitle = styled.h3`

    margin: 0%;
    line-height: 1.4;
    font-size: 20px;
    color: #b3afaf;
    font-weight: 700;

`;

export function TopSection(props){

    const { children } = props;

    return (
    <TopSectionContainer>
        <BackgroundFilter>
            {children}
            <TopSectionInnerContainer>
                <LogoContainer>
                <LogoTitle>iQonic Platform-</LogoTitle>
                <LogoSubTitle>Optical Inspection Platform for optoelectronic wafers</LogoSubTitle>
                <Marginer direction = "vertical" margin={35}/>
                <Link to = "/processscope01">
                    <Button> Join Now </Button>
                </Link>
                </LogoContainer>
                <LogoImage>
                    <img src={TheLogo} alt="Logo" />
                </LogoImage>

            </TopSectionInnerContainer>
        </BackgroundFilter>
    </TopSectionContainer>
    );
}