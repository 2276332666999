
//import ResponsiveAppBar from "./components/ResponsiveAppBar";
//import BottomAppBar from "./components/BottomAppBar";
//import Topbar from "./components/Topbar/Topbar";
import { HomePage } from "./containers/HomePage";
import styled from "styled-components";

import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react-v1';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CustomerAccessPage } from "./components/customerAccessPage";
import { Loadimages } from "./components/loadimages";

function App({ signOut }) {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path="/" element={<HomePage title="Home"/>}/>
        <Route path="/processscope01" element={<Loadimages title="About"/>}/>
      </Routes>
    </Router>
    </div>
  );
}

export default withAuthenticator(App);
