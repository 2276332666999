import React, { useState, useEffect } from 'react';
//import './App.css';
import { API, Storage } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react-v1';
import { listNotes, listTodos } from '../../graphql/queries';
import { createTodo as createNoteMutation, deleteTodo as deleteNoteMutation } from '../../graphql/mutations';

import ResponsiveAppBar from '../Topbarv2/ResponsiveAppBar';
import "@aws-amplify/ui-react/styles.css";

import { ThemeProvider } from '@mui/styles';
import { makeStyles } from '@mui/styles';
//import Button from '@mui/material/Button';
import RecipeReviewCard from '../card';
import styled from "styled-components";
import { Marginer } from "../../components/marginer";


import TopSectionBackgroundImg from "../../images/iQoniqBG.jpg"
import { InnerPageContainer, PageContainer } from '../PageContainer';
import { TopSection } from '../../containers/HomePage/Topsection';
import FileUploader from './fileuploader';
import {Delete} from '@styled-icons/material'
import { Footer } from '../footer';

const DelIcon = styled(Delete)`
  color: #f60202;
  max-width: 30px;

`

const BottomSectionContainer = styled.div`
    width: 100%;
    height: 350px;
    background: url(${TopSectionBackgroundImg});
    background-position: 0px -100px;
    background-size: cover;
`;

const TopSectionContainer = styled.div`
    width: 100%;
    height: 350px;
    background: url(${TopSectionBackgroundImg});
    background-position: 0px -350px;
    background-size: cover;
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(24, 10, 46, 0.869);
  display: flex;
  flex-direction: column;
`

const TopSectionInnerContainer = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: space-evenly;
`;



const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #f45376 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-weight: 900;
  color: #000;
`;

const TitleSub = styled.h2`
  font-weight: 400;
  color: #cf8121;
`;

const TitleSub2 = styled.h3`
font-weight: 600;
color: #f3dd51;
`;

const ButtonsContainerH = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    border-left: 4px;
    flex-direction: column;
`;

const ButtonsDublexContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
`;


const theme = {
  blue: {
    default: "#3f51b5",
    hover: "#283593"
  },
  red: {
    default: "#b80606",
    hover: "#e74545"
  },
  pink: {
    default: "#e91e63",
    hover: "#ad1457"
  }
};

const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

Button.defaultProps = {
  theme: "blue"
};



const LogoTitle = styled.h2`

    margin: 0%;
    font-size: 35px;
    color: #fff;
    font-weight: 900;

`;

const LogoSubTitle = styled.h3`

    margin: 0%;
    line-height: 1.4;
    font-size: 20px;
    color: #b3afaf;
    font-weight: 700;

`;



const initialFormState = { name: '', description: '', keyname: 'Not Processed' }

export function Loadimages(props){
  const [notes, setNotes] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  
  

  useEffect(() => {
    const interval = setInterval(() => {fetchNotes();}, 20000);
    //(fetchNotes(), 20000);
    return () => clearInterval(interval);
    //fetchNotes();
  }, []);

  async function onChange(e) {
    if (!e.target.files[0]) return
    
    const file = e.target.files[0];
    setFormData({ ...formData, image: file.name , description: file.name});
    await Storage.put(file.name, file, { level: 'public' });
    fetchNotes();
  }

  async function fetchNotes() {
    const apiData = await API.graphql({ query: listTodos });
    const notesFromAPI = apiData.data.listTodos.items;
    await Promise.all(notesFromAPI.map(async note => {
    if (note.image) {
      const image = await Storage.get(note.image, { level: 'public' });
      note.image = image;
    }
    return note;
    }))
  setNotes(apiData.data.listTodos.items);
  }
/*
async function fetchNotes() {
  const apiData = await API.graphql({ query: listNotes });
  setNotes(apiData.data.listNotes.items);
}
*/

  async function createNote() {
    if (!formData.name || !formData.description) return;
    await API.graphql({ query: createNoteMutation, variables: { input: formData } });
    if (formData.image) {
      const image = await Storage.get(formData.image, { level: 'public' });
      formData.image = image;
    }
    setNotes([ ...notes, formData ]);
    setFormData(initialFormState);
  }

/*
  async function createNote() {
    if (!formData.name || !formData.description) return;
    await API.graphql({ query: createNoteMutation, variables: { input: formData } });
    setNotes([ ...notes, formData ]);
    setFormData(initialFormState);
  }
*/
  async function deleteNote({ id }) {
    const newNotesArray = notes.filter(note => note.id === id);
    
    await Promise.all(newNotesArray.map(async note => {
      console.log(note.description);
      await Storage.remove(note.description, { level: 'public' }); 
    }))
    //console.log(notes);

    const newNotesArrayR = notes.filter(note => note.id !== id);
    setNotes(newNotesArrayR);
    //const image = await Storage.get({id}.image);
    //console.log(notes[id].image);
    //await Storage.remove(newNotesArray[0].image); 
    
    await API.graphql({ query: deleteNoteMutation, variables: { input: { id } }});
    fetchNotes();
  }
  //<DelIcon/>
  //<FileUploader type="file" onChange={onChange}/>
  return (
    
      <PageContainer>
      <TopSectionContainer>
        <BackgroundFilter>
            
            <TopSectionInnerContainer>
                
                <ButtonsContainer>
                  <LogoTitle>iQonic Platform</LogoTitle>
                  <LogoSubTitle>Optical Inspection Platform for optoelectronic wafers</LogoSubTitle>
                </ButtonsContainer>
                
                <Marginer direction = "vertical" margin={35}/>
                <ButtonsContainer>
                    <TitleSub2>Upload a new image</TitleSub2>
                    <Marginer direction = "vertical" margin={15}/>
                    <input  
                      onChange={e => setFormData({ ...formData, 'name': e.target.value})}
                      placeholder="Image Category"
                      value={formData.name}
                    />
                    <Marginer direction = "vertical" margin={15}/>
                    <input
                      onChange={e => setFormData({ ...formData, 'description': e.target.value})}
                      placeholder="Image description"
                      value={formData.description}
                    />
                    <Marginer direction = "vertical" margin={5}/>
                    
                    <input type="file" onChange={onChange} />
                    <Button theme="pink" onClick={createNote}>Import Image</Button>

                </ButtonsContainer>

            </TopSectionInnerContainer>
        </BackgroundFilter>
    </TopSectionContainer>
        
        
      

    <Marginer direction = "vertical" margin={15}/>
      <Title> Imported Images </Title>
      <Marginer direction = "vertical" margin={15}/>
      
      <InnerPageContainer>
        
      {
          notes.map(note => (
            
            <div key={note.id || note.name}>
              
              <RecipeReviewCard id={note.id} keyname={note.keyname} name={note.name} discription={note.description} image={note.image}/>
              <Marginer direction = "vertical" margin={0}/>
              <Button theme="blue" onClick={() => deleteNote(note)} >
                Delete
              </Button>

            </div>
            
          ))
        }
      <Marginer direction = "horizontal" margin={100}/>
      
      </InnerPageContainer>
      
    

      <BottomSectionContainer>
        <BackgroundFilter>
            
            <TopSectionInnerContainer>
              <ButtonsDublexContainer> 
                <ButtonsContainerH>
                  <LogoTitle>Want to partner with us?</LogoTitle>
                  <LogoSubTitle>if you are interested in our partnership and would like to find out some more information,</LogoSubTitle>
                  <LogoSubTitle>one of our advisors is excited to help. Contact us now</LogoSubTitle>
                </ButtonsContainerH>
                <Marginer direction = "vertical" margin={35}/>
                

                <Footer/>
              </ButtonsDublexContainer>
              
            </TopSectionInnerContainer>
        </BackgroundFilter>
    </BottomSectionContainer>
        
 
    
    </PageContainer>
    
    
  );
}